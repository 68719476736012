import type { BaseIconName } from '@legacy-studio/library';
import { useRegistrationPageLink, useRoutes } from '~/src/shared/lib';

export const useLinks = () => {
	const routes = useRoutes();

	return computed(() => [
		{
			href: routes.value.about,
			title: 'О нас',
		},
		{
			href: routes.value.education,
			title: 'Обучение',
		},
		{
			href: routes.value.documents,
			title: 'Документы',
		},
		{
			href: routes.value.contacts,
			title: 'Контакты',
		},
	]);
};

export const useFeatures = () => {
	return computed<
		Array<{ title: string; description: string; icon: BaseIconName }>
	>(() => [
		{
			title: 'Минимальный депозит $10',
			icon: 'base--fill--currency-dollar',
			description:
        'Это делает торговлю на платформе доступной для широкой аудитории пользователей',
		},
		{
			title: 'Минимальная сделка $1',
			icon: 'base--fill--rates',
			description:
        'Минимальная сумма сделки составляет всего $1, что позволяет начать торговлю с минимальными рисками.',
		},
		{
			title: 'Учебный демо счет',
			icon: 'base--fill--academic-cap',
			description:
        'Это отличный способ ознакомиться с платформой, разобраться в инструментах и стратегиях, и приобрести необходимый опыт.',
		},
		{
			title: 'Бесплатное обучение',
			icon: 'base--fill--information-circle',
			description:
        'Получите необходимые знания и навыки, которые позволят вам успешно торговать на платформе',
		},
		{
			title: 'Поддержка  24/7',
			icon: 'base--fill--hand',
			description:
        'Мы предоставляем круглосуточную техническую поддержку, которая поможе трешить любые вопросы, связанные с платформой',
		},
		{
			title: 'Высокая прибыльность',
			icon: 'base--fill--receipt-tax',
			description:
        'Торгуя на платформе BBroker, вы можете получить до 90% прибыли от вашего вложения.',
		},
	]);
};

export const useFunds = () => {
	return computed<
		Array<{
			title: string;
			note: string;
			items: Array<{ width: number; icon: BaseIconName; title?: string }>;
		}>
	>(() => [
		{
			title: 'Электронные кошельки и банковские карты',
			note: 'А также STEAM, YOOMONEY, PERFECT MONEY',
			items: [
				{
					icon: 'brand--filled--logos--mastercard',
					width: 64,
				},
				{
					icon: 'brand--filled--logos--visa',
					width: 83,
				},
				{
					icon: 'funds/qiwi',
					width: 97,
				},
				{
					icon: 'funds/webmoney',
					width: 105,
				},
				{
					icon: 'funds/gpay',
					width: 79,
				},
				{
					icon: 'funds/applepay',
					width: 89,
				},
			],
		},
		{
			title: 'Криптовалюта',
			note: 'А также ZEC, XMR, WAVES, LTC, XRP',
			items: [
				{
					icon: 'funds/trc-20',
					title: 'USDT (TRC-20)',
					width: 64,
				},
				{
					icon: 'funds/erc-20',
					title: 'USDT (ERC-20)',
					width: 64,
				},
				{
					icon: 'funds/btc',
					title: 'BTC',
					width: 64,
				},
				{
					icon: 'funds/trx',
					title: 'TRX',
					width: 64,
				},
				{
					icon: 'funds/eth',
					title: 'ETH',
					width: 64,
				},
				{
					icon: 'funds/bch',
					title: 'BCH',
					width: 64,
				},
			],
		},
	]);
};

export const useEasyStart = () => {
	const registrationPageLink = useRegistrationPageLink();

	return computed(() => ({
		title: 'Начать проще простого',
		description: 'Всего несколько шагов, чтобы начать',
		image: '/img/easyStart/img.png',
		items: [
			{
				title: 'Зарегистрируйтесь',
				description: 'Откройте счет бесплатно в пару кликов',
				icon: 'base--fill--cursor-click',
			},
			{
				title: 'Прочитайте инструкцию',
				description: 'Мы настоятельно рекомендуем ознакомиться с инструкцией',
				icon: 'base--fill--academic-cap',
			},
			{
				title: 'Получите $ 10,000 на демо счет',
				description: 'Тренируйтесь бес риска потери денежных средств',
				icon: 'base--fill--currency-dollar',
			},
			{
				title: 'Откройте реальный счет',
				description:
          'Пополните счет минимум на $10 и начните зарабатывать реальные средства на изменении цены активов',
				icon: 'base--fill--credit-card',
			},
		] as Array<{
			title: string;
			description: string;
			icon: BaseIconName;
		}>,
		btn: {
			title: 'Начать бесплатно',
			link: registrationPageLink.value,
		},
	}));
};

export const useOverview = () => {
	return computed(() => [
		{
			title: `Список активов`,
			description: `Выбрав нужный актив и правильно предсказав его движение на рынке, вы можете заработать прибыль в несколько раз больше, чем в случае с обычной торговлей на бирже.`,
			image: '/img/overview/ru/assets.png',
			note: `
        Кроме того, это уникальный способ заработка, который позволяет получать доход, 
        не обладая большим опытом и знаниями в финансовой сфере. Вы сможете зарабатывать деньги, просто предсказывая движение цены актива на рынке.
      `,
			items: [
				'Валютные пары',
				'Криптовалюта',
				'Сырье',
				'Индексы',
				'Акции',
				'ОТС',
			],
			reverse: false,
		},
		{
			image: '/img/overview/ru/indicators.png',
			title: 'Бесплатные и платные турниры',
			description:
        'Участие в турнирах позволит вам получить ценный опыт и улучшить свои навыки торговли на рынке, а также получить призовые деньги. Вы сможете узнать, как работает рынок и как правильно предсказывать движение цены актива.',
			note: 'Не упустите свой шанс заработать деньги и начать торговать на рынке. Используйте все возможности платформы и начните зарабатывать уже сегодня!',
			items: [
				'Всего от 5 до 15 $ стоимость участия в платном турнире',
				'Победители платных турниров получают призы в виде реальных средств',
				'Победители бесплатных турниров получают призы в виде бонусов',
				'Одновременное участие в нескольких турнирах',
			],
			reverse: true,
		},
		{
			image: '/img/overview/ru/charts.png',
			title: 'Инструменты платформы',
			description:
        'С помощью различных инструментов и графиков, вы сможете анализировать рынок и принимать правильные решения при торговле на платформе. Вы сможете использовать различные индикаторы и рисования, чтобы увеличить свою прибыль и заработать больше денег.',
			note: `
        Информация об акциях на этом графике предоставлена <a href="https://www.tradingview.com/" target="_blank" class="text-primary-300 underline">TradingView</a>, 
        платформой для построения графиков, предлагающей универсальные рыночные данные и расширенные инструменты анализа рынка,
        включая <a href="https://www.tradingview.com/economic-calendar/" target="_blank" class="text-primary-300 underline">экономический календарь</a>, позволяющий отслеживать важные экономические события. 
        Предоставляемая сторонним сервисом, информация отображается с задержкой примерно 
        на 20 минут и не предназначена для торговых целей.
      `,
			items: [
				'Временный интервалы',
				'4 типа графиков: Область, Хайкен Аши, Японские свечи, Бары',
				'Инструменты рисования',
				'Индикаторы',
			],
			reverse: false,
		},
	]);
};

export const useContacts = () => {
	return computed<
		Array<{
			icon: BaseIconName;
			link: string;
			title: string;
			description: string;
			btnText: string;
		}>
	>(() => [
		{
			icon: 'base--fill--mail',
			link: 'TODO',
			title: 'E-mail',
			description:
        'Свяжитесь с нами по почте support@bbroker.net для получения быстрой и надежной поддержки',
			btnText: 'Написать на почту',
		},
		{
			icon: 'socials--fill--telegram-white',
			link: 'TODO',
			title: 'Мы в Telegram',
			description:
        'Присоединяйтесь к нашему телеграмм каналу для обновлений и эксклюзивных предложений',
			btnText: 'Перейти в Telegram',
		},
		{
			icon: 'base--fill--question-mark-circle',
			link: 'TODO',
			title: 'FAQ',
			description:
        'Здесь вы найдете ответы на часто задаваемые вопросы о платформе BBroker',
			btnText: 'Перейти в раздел',
		},
	]);
};

export const usePartnerProgram = () => {
	const registrationPageLink = useRegistrationPageLink();

	return computed(() => ({
		title: 'Партнерская программа BBroker',
		description:
      'Присоединяйтесь к партнерской программе уже сегодня и начните зарабатывать на успешном продвижении простого и удобного финансового инструмента!',
		image: '/img/partners/img.png',
		items: [
			{
				title: '75%',
				description:
          'Максимальная доходность по тарифу <span class=\'base-link\'>REVSHARE</span>',
			},
			{
				title: 'ОТ $10 ',
				description: 'Вознаграждение за первый депозит трейдера',
			},
			{
				title: 'ОТ 2%',
				description: 'Доход от оборота трейдера',
			},
			{
				title: '5% ',
				description: 'Вознаграждение с дохода партнера',
			},
		],
		btn: {
			title: 'Стать партнером',
			link: registrationPageLink.value,
		},
	}));
};
